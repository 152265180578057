import './App.css';

function App() {

  return (
    <div>
      <iframe src='https://sdk.examedi.com/momentu' title='examedi-momentu'/>
    </div>
  );
}

export default App;
